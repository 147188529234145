import type { AppProps } from 'next/app'
import GlobalContextsProvider from 'library/layout/GlobalContextsProvider'
import GlobalStyle from 'theme/GlobalStyle'
import React from 'react'
import ThemeProvider from 'theme/ThemeProvider'

const Onetrace = ({ Component, pageProps }: AppProps) => (
  <GlobalContextsProvider>
    <ThemeProvider>
      <GlobalStyle />
      <Component {...pageProps} />
    </ThemeProvider>
  </GlobalContextsProvider>
)

export default Onetrace
